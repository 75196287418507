import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
//import Vuetify from 'vuetify';
//import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#482e20', //Personalizado
        secondary: '#4d4c22', //Personalizado
        third: '#db8358', //Personalizado
        fourth: '#F7F7F7', //'#F5F3EF',  //Personalizado
        error: '#db8358',
        info: '#2196F3',
        success: '#482e20', //Personalizado
        warning: '#FFC107'
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});
